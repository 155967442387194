// abis
import { abi as TokenABI } from 'abis/IERC20.json';
import { abi as StakingRewardsABI } from 'abis/StakingRewards.json';

// types
import { IERC20 } from 'types/IERC20';
import { StakingRewards } from 'types/StakingRewards';
import { AllowedChainConfig, ContractConfig } from 'types/config';

// assets
import logo from 'assets/Logo.png';

export const APP_CONFIG = {
  COMPANY: {
    NAME: 'Sin City',
    LOGO: logo,
  },
  STAKING_TOKEN: 'SIN-LP',
  REWARD_TOKEN: 'SIN',
};

export const allowedChains: AllowedChainConfig[] = [{ id: 56, name: 'BSC Mainnet' },{
  id: 97,
  name: 'BSC Testnet',
},];

export const ContractAddress = {
  Token: '0x7C201aBc26717739EB4397A0474843b2276603C5',
  StakingRewards: '0xC136D7fe007a5a0e3418427CcFFCe8f0cdf56CdE',
};

export const contracts: ContractConfig[] = [
  {
    name: 'Token',
    abi: TokenABI,
    address: ContractAddress.Token,
  },
  {
    name: 'StakingRewards',
    abi: StakingRewardsABI,
    address: ContractAddress.StakingRewards,
  },
];

export interface ContractInstances {
  Token: IERC20;
  StakingRewards: StakingRewards;
}
